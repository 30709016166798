<template>
  <footer
      :class="`theme-footer-seven ${
			dark_bg ? 'dark-bg pt-120 md-pt-100 sm-pt-20' : 'mt-120 md-mt-100 sm-mt-20'
		}`"
  >
    <div class="lg-container">
      <div class="container">
        <div class="row flex-sm-row flex-column">
          <div
              class="col-xl-5 col-lg-2 mb-40"
              data-aos="fade-up"
              data-aos-duration="1200"
          >
            <div v-if="!dark_bg" class="logo">
              <NuxtLink to="/">
                <img
                    src="/images/altela-img/footer-logo.png"
                    alt=""/>
              </NuxtLink>
            </div>
            <div v-else class="logo">
              <NuxtLink to="/">
                <nuxt-img
                    src="/images/logo/deski_08.svg"
                    alt=""
                />
              </NuxtLink>
            </div>
            <div class="textUnderLogo">
              <p>
                N’hésitez-pas à nous consulter pour toute information, étude et devis, quels que soient votre domaine
                d’activité, l’importance et particularités de votre demande.
              </p>
            </div>
            <div class="pictoFooter justify-content-between flex-wrap flex-sm-nowrap d-flex gap-5">
              <div class="picto">
                <img src="/images/altela-img/picto1.png"/>
                <span>Expedition rapide</span>
              </div>
              <div class="picto">
                <img src="/images/altela-img/picto2.png"/>
                <span>Devis immediat</span>
              </div>
              <div class="picto">
                <img src="/images/altela-img/picto3.png"/>
                <span>Fabrication sur-mesure</span>
              </div>
              <div class="picto">
                <img src="/images/altela-img/picto4.png"/>
                <span>Fabrication 100% française</span>
              </div>
            </div>
          </div>
          <div
              class="col-lg-2 col-md-6 mb-40"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="100"
          >
            <h5 class="title">Contactez-nous</h5>
            <ul class="footer-list contactList">
              <li class="mb-4">
                <i class="fa-solid fa-phone"></i>
                <span>+33 (0)5 62 33 90 84</span>
              </li>
              <li class="mb-4">
                  <i class="fa-solid fa-fax"></i>
                  <span>+33 (0)5 62 33 85 86</span>
              </li>
              <li class="mb-4">
                <a href="mailto:contact@altela.com" class="d-flex align-items-center gap-3">
                  <i class="fa-solid fa-envelope"></i>
                  <span>contact@altela.com</span>
                </a>
              </li>
              <li class="mb-4">
                  <i class="fa-solid fa-location-dot"></i>
                  <span>ZI Lasgarrènes <br/>65600 SÉMÉAC - France</span>
              </li>
              <li class="socialNetwork">
                <span>Suivez-nous</span>
                <div class="d-flex align-items-center gap-3">
                  <a href="https://www.facebook.com/AltelaFilets" target="_blank">
                    <i class="fa-brands fa-square-facebook"></i>
                  </a>
                  <a href="https://www.youtube.com/channel/UCaX8LKglmzv09vrt8ktf_Cw/videos" target="_blank">
                    <i class="fa-brands fa-square-youtube"></i>
                  </a>
                  <a href="https://www.linkedin.com/company/altela/" target="_blank">
                    <i class="fa-brands fa-linkedin"></i>
                  </a>
                </div>
              </li>
            </ul>
          </div>
          <div
              class="col-lg-2 col-md-6 mb-40 altelaMenuFooter"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="150"
          >
            <h5 class="title">Altela</h5>
            <ul class="footer-list">
              <li><NuxtLink href="/#nos-domaines"><span>Nos domaines</span></NuxtLink></li>
              <li><NuxtLink to="/liste-realisation"><span>Nos réalisations</span></NuxtLink></li>
              <li><a href="/docs/catalogue-en-ligne/2022%20-%20Catalogue-ALTELA.pdf" target="_blank"><span>Catalogues en ligne</span></a></li>
              <li><NuxtLink href="/nous-contacter"><span>Nous contacter</span></NuxtLink></li>
            </ul>
          </div>
          <div
              class="col-xl-2 col-lg-5 mb-40 infoMenuFooter"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
          >
            <h5 class="title">Informations</h5>
            <ul class="footer-list">
              <li><NuxtLink to="/entreprise"><span>L' entreprise</span></NuxtLink></li>
              <li><NuxtLink to="/savoir-faire"><span>Savoir-faire</span></NuxtLink></li>
              <li><NuxtLink to="/nous-contacter"><span>Demande de devis</span></NuxtLink></li>
              <li><NuxtLink to="/mentions-legales"><span>Mentions légales</span></NuxtLink></li>
            </ul>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="bottom-footer">
          <div class="row">
            <div class="col-lg-10 order-lg-1 mb-20">
              <ul
                  class="d-flex justify-content-center justify-content-lg-start flex-wrap footer-nav"
              >
                <li><a href="#">@ALTELA 2024</a></li>
                <li><NuxtLink to="/cgv">Conditions générales de vente</NuxtLink></li>
                <li><NuxtLink to="/mentions-legales">Mentions légales</NuxtLink></li>
                <li><a href="https://www.graphibox.biz/" target="_blank">Site réalisé par Graphibox</a></li>
              </ul>
            </div>
            <div class="col-lg-2 order-lg-2 mb-20">
              <div class="backTop">
                <i class="fa-solid fa-arrow-up"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /.lg-container -->
  </footer>
</template>

<script>
export default {
  name: "FooterSeven",
  props: {
    dark_btn: Boolean,
    dark_bg: Boolean,
  },
  methods: {
    onSubmit() {
      console.log("Submitted");
    },
  },
};
</script>
<style scoped>
.theme-footer-seven {
  font-family: Roboto;
}

.theme-footer-seven .footer-list a {
  line-height: normal;
}

.logo img {
  width: 30%;
}

.lg-container .container {
  max-width: 1640px;
}

.row {
  flex-wrap: nowrap;
  justify-content: space-between;
}

.textUnderLogo {
  font-size: 14px;
  margin-top: 1rem;
  color: #093888;
  line-height: normal;
}

.pictoFooter {
  margin-top: 3rem;
}

.pictoFooter .picto {
  text-align: center;
  text-transform: uppercase;
  line-height: normal;
  font-size: 14px;
  color: #000000;
  font-weight: 700;
  font-family: 'Panton';
}

.pictoFooter .picto img {
  width: 55px;
  margin: auto;
  margin-bottom: 1rem;
}

.title {
  font-family: Panton;
  font-style: italic;
  letter-spacing: 0.5px;
  color: #000;
  font-size: 15px;
}

.contactList li {
  color: #000;
}

.contactList li {
  display: flex;
  gap: 1rem !important;
  align-items: center;
}

.contactList li i {
  color: #D9000D;
}

.footer-list {
  font-size: 16px;
}

.footer-list li:nth-child(1) a {
  font-weight: 700;
}

.altelaMenuFooter .footer-list li:nth-child(1) a,
.infoMenuFooter .footer-list li:nth-child(1) a {
  font-weight: 400;
}

.footer-list li:nth-child(4) a {
  line-height: normal;
}

.footer-list li a:hover {
  text-decoration: none;
}

.footer-list li a i {
  color: #D9000D;
}

.footer-list a:hover span,
.footer-nav a:hover span {
  border-bottom: 2px solid #D9000D;
}

.socialNetwork {
  color: #000;
  margin-top: 4rem;
  font-family: Panton;
  text-transform: uppercase;
  font-style: italic;
  font-size: 17px;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}

.socialNetwork i {
  font-size: 24px;
}

.socialNetwork a i {
  color: inherit !important;
}

.backTop {
  text-align: end;
}

.backTop i {
  padding: 12px 15px;
  border: 1px solid #D9000D;
  color: #D9000D;
  border-radius: 10px;
}

@media screen and (max-width: 1600px) {
  .theme-footer-seven {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media screen and (max-width: 810px) {
  .picto {
    width: 40%;
  }
}

@media screen and (max-width: 500px) {
  .pictoFooter {
    justify-content: center !important;
  }

  .footer-nav li a {
    text-align: center;
  }
}
</style>
