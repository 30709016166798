<template>
  <div
      :class="`theme-main-menu sticky-menu theme-menu-six bg-none header ${
			isSticky ? 'fixed' : ''
		}`"
  >
    <div class="d-flex justify-content-between topHeader">
      <div class="d-flex justify-content-between gap-5">
        <div class="text-uppercase service-client">
          <i class="fa-solid fa-phone"></i>
          <span>Service client +33 (0)5 62 33 90 84</span>
        </div>
        <div class="contact-us">
          <NuxtLink to="/nous-contacter">
            <i class="fa-regular fa-envelope"></i>
            <span>Contactez-nous</span>
          </NuxtLink>
        </div>
        <div class="text-uppercase navMenu1">
          <NuxtLink to="/entreprise">
            <span>L'entreprise</span>
          </NuxtLink>
          <span>|</span>
          <NuxtLink to="/savoir-faire">
            <span>Savoir-faire</span>
          </NuxtLink>
        </div>
      </div>
      <div class="d-flex justify-content-between gap-5 services">
        <div class="d-flex text-uppercase navMenu2">
          <div>
            <img src="/images/altela-img/speed-expedition.png" alt="speed-expedition">
          </div>
          <span>Expédition <br/> rapide</span>
        </div>
        <div class="d-flex text-uppercase navMenu2">
          <div>
            <img src="/images/altela-img/fabrication-sur-mesure.png" alt="speed-expedition">
          </div>
          <span>Fabrication <br/> sur-mesure</span>
        </div>
        <div class="d-flex text-uppercase navMenu2">
          <div>
            <img src="/images/altela-img/devis-immediat.png" alt="speed-expedition">
          </div>
          <span>Devis <br/> immédiat</span>
        </div>
        <div class="d-flex text-uppercase navMenu2">
          <div>
            <img src="/images/altela-img/france-flag.png" alt="speed-expedition">
          </div>
          <span>Fabrication <br/> 100% Francaise</span>
        </div>
      </div>
    </div>
    <div class="d-flex subHeader">
      <div class="logo order-lg-1">
        <NuxtLink to="/">
          <img src="/images/altela-img/header-logo.png" alt=""/>
        </NuxtLink>
      </div>
      <nav id="mega-menu-holder" class="navbar navbar-expand-lg order-lg-2">
        <div class="nav-container">
          <button
              class="navbar-toggler"
              data-bs-toggle="collapse"
              data-bs-target="#collapsibleNavbar"
              :class="
							menuActive
								? 'navbar-toggler open'
								: 'navbar-toggler'
						"
              @click="menuActive = !menuActive"
          >
            <span></span>
          </button>
          <div
              :class="
							menuActive
								? 'navbar-collapse collapse ss'
								: 'navbar-collapse collapse'
						"
              id="collapsibleNavbar"
          >
            <div
                class="divMenu d-lg-flex justify-content-between align-items-center"
            >
              <ul
                  class="navbar-nav main-side-nav font-gordita"
                  id="one-page-nav"
              >
                <li class="nav-item dropdown position-static" id="filets-securite">
                  <a href="#" class="nav-link" data-bs-toggle="dropdown"
                  >
                    Filets de sécurité
                  </a>
                  <div class="dropdown-menu">
                    <!-- megaMenus start -->
                    <SubMenu :menu="1" color="#D9000D"/>
                    <!-- megaMenus end -->
                  </div>
                </li>
                <li class="nav-item dropdown position-static" id="filets-protection">
                  <a href="#" class="nav-link" data-bs-toggle="dropdown"
                  >
                    Filets de protection
                  </a>
                  <div class="dropdown-menu">
                    <!-- megaMenus start -->
                    <SubMenu :menu="2" color="#093888"/>
                    <!-- megaMenus end -->
                  </div>
                </li>
                <li class="nav-item dropdown position-static" id="filets-environnement">
                  <a href="#" class="nav-link" data-bs-toggle="dropdown"
                  >
                    Filets environnement
                  </a>
                  <div class="dropdown-menu">
                    <!-- megaMenus start -->
                    <SubMenu :menu="3" color="#118D16"/>
                    <!-- megaMenus end -->
                  </div>
                </li>
                <li class="nav-item dropdown position-static" id="filets-sports-loisirs">
                  <a href="#" class="nav-link" data-bs-toggle="dropdown"
                  >
                    Filets sport et loisirs
                  </a>
                  <div class="dropdown-menu">
                    <!-- megaMenus start -->
                    <SubMenu :menu="4" color="#318CE7"/>
                    <!-- megaMenus end -->
                  </div>
                </li>
                <li class="nav-item dropdown position-static" id="securite-provisoire">
                  <a href="#" class="nav-link" data-bs-toggle="dropdown"
                  >
                    Sécurité provisoire
                  </a>
                  <div class="dropdown-menu">
                    <!-- megaMenus start -->
                    <SubMenu :menu="5" color="#D8922A"/>
                    <!-- megaMenus end -->
                  </div>
                </li>
                <li class="nav-item dropdown position-static" id="cordages-accessoires">
                  <a href="#" class="nav-link" data-bs-toggle="dropdown">
                    Cordages et accessoires
                  </a>
                  <div class="dropdown-menu">
                    <!-- megaMenus start -->
                    <SubMenu :menu="6" color="#000"/>
                    <!-- megaMenus end -->
                  </div>
                </li>
                <li class="nav-item dropdown position-static" id="filets-ignifuges">
                  <a href="#" class="nav-link" data-bs-toggle="dropdown"
                  >
                    Filets ignifugés
                  </a>
                  <div class="dropdown-menu">
                    <!-- megaMenus start -->
                    <SubMenu :menu="7" color="#6B9AAF"/>
                    <!-- megaMenus end -->
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
      <!--<div class="order-lg-3 icon-search">
        <i class="fa-solid fa-magnifying-glass"></i>
      </div>-->
    </div>
  </div>
</template>

<script setup>
import SubMenu from "@/components/altela/SubMenu/index.vue";

const isSticky = ref(false)
const menuActive = ref(false)

const handleSticky = () => {
  if (window.scrollY > 80) {
    isSticky.value = true;
  } else {
    isSticky.value = false;
  }
}

onMounted(() => {
  window.addEventListener("scroll", handleSticky);
})

</script>
<style scoped>
.theme-main-menu {
  padding: 0;
}

.header {
  background-color: #D9000D;
  font-family: Roboto;
  box-shadow: 0px 11px 27px 0px rgba(0, 0, 0, 0.19);
}

.topHeader {
  color: #fff;
  font-family: Roboto;
  padding: 5px 160px;
}

.subHeader {
  background-color: #fff;
  padding: 0 140px;
  column-gap: 2rem;
}

.nav-container, .navbar-collapse, .divMenu, .navbar .navbar-collapse .navbar-nav {
  height: 100%;
}

.service-client {
  font-size: 16px;
  display: flex;
  align-items: baseline;
  column-gap: .3rem;
  font-weight: 700;
}

.contact-us {
  font-size: 16px;
}

.contact-us a {
  text-decoration: none;
  display: flex;
  color: inherit;
  align-items: baseline;
  column-gap: .3rem;
}

.navMenu1 {
  font-size: 16px;
  display: flex;
  align-items: baseline;
  column-gap: .5rem;
  font-weight: 700;
}

.navMenu1 a {
  text-decoration: none;
  color: inherit;
}

.navMenu2 {
  font-size: 12px;
  display: flex;
  align-items: center;
  column-gap: .3rem;
}

.navMenu2 span {
  line-height: normal;
}

#mega-menu-holder {
  background-color: #003853;
}

.nav-link {
  text-transform: uppercase !important;
  font-size: 15px !important;
  font-family: Panton;
  font-style: italic;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}

.nav-link {
  margin: 0 !important;
  padding: 30px 25px !important;
}

.nav-item {
  border-right: 1px solid #648B9E;
  transition: all .3s ease-in-out;
  display: flex;
  align-items: center;
}

.icon-search {
  color: #003853;
  display: flex;
  align-items: center;
}

.theme-main-menu.sticky-menu.fixed {
  background: #D9000D;
}

.theme-menu-six .navbar-nav .nav-item .nav-link, .theme-menu-six.fixed .right-widget .signIn-action {
  color: #fff;
}

#filets-securite:hover {
  background-color: #D9000D;
}

#filets-protection:hover {
  background-color: #093888;
}

#filets-environnement:hover {
  background-color: #118D16;
}

#cordages-accessoires:hover {
  background-color: #000;
}

#filets-sports-loisirs:hover {
  background-color: #318CE7;
}

#securite-provisoire:hover {
  background-color: #D8922A;
}

#filets-ignifuges:hover {
  background-color: #6B9AAF;
}

.dropdown-menu {
  right: 5%;
  left: 7%;
  padding: 1.25rem;
}

@media screen and (max-width: 1600px) {
  .topHeader {
    padding: 1rem;
    flex-wrap: wrap;
    row-gap: 0.5rem;
    //justify-content: center !important;
  }

  .subHeader {
    padding: 0 20px;
  }
}

@media screen and (max-width: 1440px) {

  .subHeader {
    padding: 0 140px;
  }

  .logo {
    width: 83%;
  }

  #mega-menu-holder, .navbar-toggler {
    background-color: #D9000D;
  }

  .navbar-toggler {
    display: block;
  }

  #mega-menu-holder {
    padding: 0 !important;
  }

  .navbar-collapse {
    position: absolute;
    top: 100%;
    background-color: #003853;
    left: 0px;
    right: 0px;
    max-height: 70vh;
    overflow-y: auto;
    box-shadow: 0px 40px 50px 0px rgba(137, 139, 142, 0.15);
    display: block !important;
    transform: scale(1, 0);
    transform-origin: 100% 0;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
  }

  .divMenu {
    display: block !important;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: column;
  }

  .navbar-collapse.show {
    transform: scale(1, 1);
    opacity: 1;
    visibility: visible;
  }

  .navbar .navbar-collapse .navbar-nav .nav-item .nav-link {
    border-top: 1px solid #f3f3f3;
  }

  .navbar .dropdown-menu {
    display: none;
  }

  .navbar .dropdown-menu.show {
    display: block !important;
    position: static;
  }

  .navbar .navbar-collapse .navbar-nav .nav-item {
    display: block;
  }

  .nav-container, .navbar-collapse, .divMenu, .navbar .navbar-collapse .navbar-nav {
    height: auto;
  }

  .theme-main-menu.sticky-menu.fixed {
    padding: 0;
  }
}

@media screen and (max-width: 1380px) {
  .topHeader {
    justify-content: center !important;
  }
}

@media screen and (max-width: 810px) {
  .service-client, .contact-us, .services {
    display: none !important;
  }

  .topHeader {
    padding: 0;
    justify-content: center !important;
  }

  .subHeader {
    padding: 0 0 0 1rem;
    justify-content: space-between !important;
  }

  .icon-search {
    display: none;
  }

  .theme-main-menu .logo {
    max-width: 100%;
  }

  .navbar-collapse {
    background-color: #003853;
  }
}
</style>
