<template>
  <div class="row gap-2">
    <div class="col" v-for="menu in showedMenu.menu">
      <div class="menuTitle d-flex gap-3" :style="`background-color: ${(menu.customColor) ? menu.customColor : color}`">
        {{ menu.title }}
        <i class="fa-solid fa-chevron-down"></i>
      </div>
      <ul>
        <li v-for="menuItem in menu.menuItem">
          <NuxtLink :to="`/detail-produit/${props.menu}/${menu.id}/${menuItem.id}`">
            {{ menuItem.title }}
          </NuxtLink>
        </li>
      </ul>
    </div>
  </div>
</template>
<script setup>
import data from "@/public/static/menu.json"

const props = defineProps({
  menu: Number,
  color: String
})
const showedMenu = computed(() => {
  return data.filter((elm) => {
    return elm.id === props.menu
  })[0]
})

</script>
<style scoped>
.menuTitle {
  color: #fff;
  font-family: Panton;
  font-size: 1.25rem;
  font-style: italic;
  font-weight: 400;
  line-height: 110%;
  letter-spacing: 0.025rem;
  text-transform: uppercase;
  padding: 0.75rem;
}

li {
  color: #003853;
  font-family: Roboto;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  letter-spacing: 0.02813rem;
  text-transform: uppercase;
  margin: 0.5rem 0;
}

a:hover {
  text-decoration: underline;
  color: inherit;
}

@media screen and (max-width: 810px) {
  .menuTitle {
    font-size: 15px;
  }

  .row {
    flex-direction: column;
  }
}
</style>